import { ReactComponent as Icon } from 'assets/icons/completed.svg'
import { Description } from 'pages/Auth/styled'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { SubmitButton, Title } from './styled'

function Completed() {
  const navigate = useNavigate()

  const onBtnClick = () => navigate(ROUTE_PATH.LOGIN)

  return (
    <>
      <Icon />
      <Title>Your password has been successfully changed</Title>
      <Description>Now you can use it to login to the application</Description>
      <SubmitButton type="primary" onClick={onBtnClick}>
        GO BACK TO LOG IN
      </SubmitButton>
    </>
  )
}

export default Completed
