import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const List = styled.ul`
  height: 350px;
  margin-top: 12px;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ebebeb;

  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    color: ${COLORS.primary.medium};
  }

  & > *:first-child {
    margin-right: 10px;
  }
`
