import backgroundSrc from 'assets/img/greeting-background.png'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: 1;
  height: 150px;
  padding: 32px;
  background-image: url(${backgroundSrc});
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  margin-bottom: 18px;
`

export const DateSection = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    padding-right: 8px;
    margin-right: 8px;

    border-right: 1px solid #d8f2ff;
  }
`

export const DateItem = styled.p`
  font-size: 20px;
  line-height: 1.2;
  color: #425486;
`
