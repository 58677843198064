/* eslint-disable no-console */
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import LocalStorage from 'utils/localStorage'

import { Auth, CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'

type User = any

type Context = {
  user: User | null | undefined
  isLoading: boolean
  actions: {
    signOut: () => Promise<void>
    checkAuth: () => Promise<void>
    signIn: (username: string, password: string) => Promise<void>
    signInWith: (provider: CognitoHostedUIIdentityProvider) => Promise<void>
    signUp: (email: string, password: string) => Promise<boolean>
    confirmSignUp: (email: string, code: string) => Promise<void>
    resendSignUp: (email: string) => Promise<void>
    resetPassword: (username: string) => Promise<void>
    confirmResetPassword: (username: string, code: string, password: string) => Promise<void>
  }
}

const AuthContext = createContext<Context>({
  user: undefined,
  isLoading: true,
  actions: {
    checkAuth: async () => void 0,
    signIn: async () => void 0,
    signInWith: async () => void 0,
    signUp: async () => true,
    confirmSignUp: async () => void 0,
    resendSignUp: async () => void 0,
    signOut: async () => void 0,
    resetPassword: async () => void 0,
    confirmResetPassword: async () => void 0,
  },
})

export const AuthProvider = (props: { children?: ReactNode }) => {
  const [state, setState] = useState(useContext(AuthContext))

  async function setUser(cognitoUser: CognitoUser | null) {
    if (cognitoUser) {
      LocalStorage.token = cognitoUser.getSignInUserSession()?.getIdToken().getJwtToken() as string

      setState({
        ...state,
        isLoading: false,
        user: cognitoUser,
      })
    } else {
      LocalStorage.token = null
      setState({
        ...state,
        isLoading: false,
        user: null,
      })
    }
  }

  async function signOut(): Promise<void> {
    try {
      await Auth.signOut()
      await setUser(null)
    } catch (error) {
      console.error('[Auth]', error)
    }
  }

  async function signIn(username: string, password: string): Promise<void> {
    try {
      const user = await Auth.signIn({ username: username.toLowerCase(), password })

      await setUser(user)
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function signInWith(provider: CognitoHostedUIIdentityProvider): Promise<void> {
    try {
      await Auth.federatedSignIn({ provider })
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function signUp(email: string, password: string): Promise<boolean> {
    const lowerCasedEmail = email.toLowerCase()
    try {
      const { userConfirmed } = await Auth.signUp({
        username: lowerCasedEmail,
        password,
        attributes: {
          email: lowerCasedEmail,
        },
      })
      return userConfirmed
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function confirmSignUp(email: string, code: string): Promise<void> {
    try {
      await Auth.confirmSignUp(email.toLowerCase(), code)
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function resendSignUp(email: string): Promise<void> {
    try {
      await Auth.resendSignUp(email.toLowerCase())
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function resetPassword(username: string): Promise<void> {
    try {
      await Auth.forgotPassword(username.toLowerCase())
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function confirmResetPassword(username: string, code: string, password: string): Promise<void> {
    try {
      await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function checkAuth(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await setUser(user)
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }))
      console.error('[Auth]', error)
    }
  }

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          await checkAuth()
          break
        case 'signOut':
          await setUser(null)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.error('[Auth] Sign in failure:', data)
          break
      }
    })

    checkAuth()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        actions: {
          checkAuth,
          signIn,
          signInWith,
          signUp,
          confirmSignUp,
          resendSignUp,
          signOut,
          resetPassword,
          confirmResetPassword,
        },
      }}
      {...props}
    />
  )
}

export function useAuth() {
  const ctx = useContext(AuthContext)

  return {
    user: ctx.user,
    isLoading: ctx.isLoading,
    ...ctx.actions,
  }
}
