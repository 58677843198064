export const mockData = [
  {
    id: '1',
    title: 'Product A',
    description: 'Short description for Product A',
    img: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
  },
  {
    id: '2',
    title: 'Product B',
    description: 'Short description for Product B',

    img: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
  },
  {
    id: '3',
    title: 'Product C',
    description: 'Short description for Product C',

    img: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
  },
  {
    id: '4',
    title: 'Product D',
    description: 'Short description for Product D',

    img: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
  },
  {
    id: '5',
    title: 'Product E',
    description: 'Short description for Product E',

    img: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
  },
  {
    id: '6',
    title: 'Product F',
    description: 'Short description for Product F',

    img: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
  },
  {
    id: '7',
    title: 'Product G',
    description: 'Short description for Product G',

    img: 'https://play-lh.googleusercontent.com/IeNJWoKYx1waOhfWF6TiuSiWBLfqLb18lmZYXSgsH1fvb8v1IYiZr5aYWe0Gxu-pVZX3',
  },
]
