import { Spin } from 'antd'

import { Wrapper } from './styled'

function Loader() {
  return (
    <Wrapper>
      <Spin />
    </Wrapper>
  )
}

export default Loader
