import { Button } from 'antd'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 400px;
  height: 540px;
  padding: 20px 24px;
`

export const CloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 20px;
  right: 24px;

  cursor: pointer;
`

export const Heading = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  padding-bottom: 20px;

  border-bottom: 1px solid #ebebeb;
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const NoItemsTitle = styled.h4`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3;
  color: ${COLORS.text.default};

  margin: 16px 0 8px;
`

export const NoItemsDescription = styled.p`
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #696b7b;
`

export const JoinButton = styled(Button)`
  width: 176px;
  margin-top: 24px;
`
