import { Menu as ANTDMenu, Layout } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const { Sider: ANTDSider } = Layout

export const Sider = styled(ANTDSider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  &.ant-layout-sider {
    background-color: #f4fbff;
  }
`

export const LogoLink = styled(Link)`
  display: block;
  padding-top: 32px;
  padding-left: 32px;
  padding-bottom: 24px;
`

export const Menu = styled(ANTDMenu)`
  background: transparent;

  &.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }
`

export const MenuItem = styled(ANTDMenu.Item)`
  width: 100% !important;
  height: 48px !important;
  line-height: 48px !important;
  padding-left: 32px !important;
  padding-right: 0px !important;
  margin: 8px 0 !important;
  border-radius: 0 !important;

  &.ant-menu-item-selected::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 48px;
    width: 4px;
    background: #0082cd;
    box-shadow: 1px 0px 20px rgba(255, 255, 255, 0.2);
    border-radius: 0px 8px 8px 0px;
  }

  .ant-menu-title-content {
    font-weight: 500;
    margin-inline-start: 10px !important;
  }
  &.ant-menu-item-selected {
    background: linear-gradient(270deg, rgba(223, 243, 255, 0) 0%, #dff3ff 100%);
  }
`

export const Divider = styled.div<{ bottom?: boolean }>`
  height: 1px;
  background-color: #d9ecf6;

  margin: ${({ bottom }) => (bottom ? '20px 32px 20px 32px' : '16px 32px 28px 32px')};
`

export const Label = styled.p<{ bottom?: boolean }>`
  margin: ${({ bottom }) => (bottom ? '100px 0 20px 35px' : '0 0 20px 35px')};

  font-weight: 600;
  font-size: 12px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: #677fa9;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 0px 32px 20px 32px;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100%;

    &:first-child {
      margin-right: 8px;
    }
  }
`
