import { Form as ANTDForm, Input } from 'antd'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Description, FakeLink, Form, FormItem, Heading, SubmitButton } from '../../styled'

const { Password } = Input

type Props = { onSubmit: () => void }

function Active({ onSubmit }: Props) {
  const { resetPassword, confirmResetPassword } = useAuth()

  const location = useLocation()

  const [isErrors, setIsErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [form] = Form.useForm()

  const code = Form.useWatch('code', { form })
  const password = Form.useWatch('password', { form })
  const confirmPassword = Form.useWatch('confirm-password', { form })

  useEffect(() => {
    if (code && password && confirmPassword) {
      form
        .validateFields()
        .then(() => setIsErrors(false))
        .catch(() => {
          setIsErrors(true)
        })
    }
  }, [code, password, confirmPassword, form])

  const onResetPassword = async () => {
    setIsLoading(true)
    setError('')
    try {
      await confirmResetPassword(location.state.email, code, password)
      onSubmit()
    } catch (e) {
      setError(e.message)
    }
    setIsLoading(false)
  }

  const onResendCode = async () => {
    setError('')
    try {
      await resetPassword(location.state.email)
    } catch (e) {
      setError(e.message)
    }
  }

  const isDisabled = !code || !password || !confirmPassword || isErrors

  return (
    <>
      <Heading>Create new password</Heading>
      <Description>
        Securely access your account by creating
        <br />a new log in password
      </Description>
      <Form
        form={form}
        layout="vertical"
        onFinish={onResetPassword}
        hideRequiredMark
        onValuesChange={() => setError('')}
      >
        <FormItem
          name="code"
          label="Verification code"
          required
          rules={[
            { required: true, message: '' },
            { pattern: new RegExp('[0-9]'), message: 'Incorrect code' },
          ]}
        >
          <Input maxLength={6} placeholder="Enter your verification code" status={error ? 'error' : undefined} />
        </FormItem>
        <FakeLink onClick={onResendCode}>Resend code</FakeLink>
        <FormItem
          name="password"
          label="Password"
          required
          rules={[
            {
              required: true,
              message: '',
            },
            {
              min: 8,
              max: 20,
              message: 'Incorrect password',
            },
          ]}
        >
          <Password
            placeholder="Create password (at least 8 characters)"
            type="password"
            status={error ? 'error' : undefined}
          />
        </FormItem>
        <FormItem
          name="confirm-password"
          label="Confirm password"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject({ message: 'The two passwords that you entered do not match!' })
              },
            }),
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Password placeholder="Confirm your password" type="password" status={error ? 'error' : undefined} />
        </FormItem>
        <ANTDForm.Item noStyle>
          <SubmitButton htmlType="submit" type="primary" disabled={isDisabled} loading={isLoading}>
            CONFIRM AND CONTINUE
          </SubmitButton>
        </ANTDForm.Item>
      </Form>
    </>
  )
}

export default Active
