import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import { apolloClient } from 'graphql/apolloClient'
import { AuthProvider } from 'hooks/useAuth'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'swiper/css'
import 'utils/amplify'

import { ApolloProvider } from '@apollo/client'

import App from './App'
import GlobalStyle from './globalStyle'
import theme from './theme'

dayjs.extend(isToday)
dayjs.extend(duration)

// core Swiper

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <ConfigProvider theme={theme}>
          <App />
        </ConfigProvider>
        <GlobalStyle />
      </AuthProvider>
    </ApolloProvider>
  </React.StrictMode>
)
