import { ReactComponent as DonationsIcon } from 'assets/icons/avatar-donations.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/avatar-logout.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/avatar-profile.svg'
import { ReactComponent as BackIcon } from 'assets/icons/back-arrow.svg'
import { ReactComponent as DownArrowIcon } from 'assets/icons/down-arrow.svg'
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg'
import AvatarFallback from 'assets/img/avatar-fallback.jpg'
import NotificationsPopoverContent from 'components/business/Main/NotificationsPopoverContent'
import { Flex } from 'components/common/Flex'
import { useAuth } from 'hooks/useAuth'
import { ChangeEvent, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import {
  Avatar,
  AvatarPopover,
  AvatarPopoverItem,
  AvatarPopoverText,
  AvatarText,
  IconWrapper,
  NotificationWrapper,
  NotificationsPopover,
  ProfileWrapper,
  Search,
  Title,
  Wrapper,
} from './styled'

type SearchProps = {
  searchValue: string
  onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void
  searchPlaceholder: string
}

type Props = {
  title: string
  isBackButton?: boolean
  icon: ReactNode
  searchProps?: SearchProps
}

function Header({ title, isBackButton, icon, searchProps }: Props) {
  const { user, signOut } = useAuth()
  const navigate = useNavigate()
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isAvatarPopoverOpen, setIsAvatarPopoverOpen] = useState(false)

  const avatar: any = null

  const onCloseNotifications = () => setIsNotificationsOpen(false)

  const onLogin = () => {
    if (!user) return navigate(ROUTE_PATH.LOGIN)
  }
  return (
    <Wrapper>
      <Flex alignCenter>
        <IconWrapper onClick={isBackButton ? () => navigate(-1) : undefined} clickable={isBackButton}>
          {isBackButton ? <BackIcon /> : icon}
        </IconWrapper>
        <Title>{title}</Title>
        {searchProps && <Search />}
      </Flex>
      <Flex alignCenter>
        <NotificationsPopover
          placement="bottomRight"
          trigger="click"
          open={isNotificationsOpen}
          onOpenChange={setIsNotificationsOpen}
          content={<NotificationsPopoverContent onClose={onCloseNotifications} />}
        >
          <NotificationWrapper>
            <NotificationIcon />
          </NotificationWrapper>
        </NotificationsPopover>
        {user ? (
          <AvatarPopover
            placement="bottomRight"
            trigger="click"
            open={isAvatarPopoverOpen}
            onOpenChange={setIsAvatarPopoverOpen}
            content={
              <div>
                <AvatarPopoverItem>
                  <ProfileIcon />
                  <AvatarPopoverText>Profile</AvatarPopoverText>
                </AvatarPopoverItem>
                <AvatarPopoverItem>
                  <DonationsIcon />
                  <AvatarPopoverText>Donations history</AvatarPopoverText>
                </AvatarPopoverItem>
                <AvatarPopoverItem onClick={signOut}>
                  <LogoutIcon />
                  <AvatarPopoverText>Log out</AvatarPopoverText>
                </AvatarPopoverItem>
              </div>
            }
          >
            <ProfileWrapper>
              <Avatar src={avatar || AvatarFallback} />
              <DownArrowIcon />
            </ProfileWrapper>
          </AvatarPopover>
        ) : (
          <ProfileWrapper onClick={onLogin}>
            <Avatar src={AvatarFallback} />
            <AvatarText>LOG IN</AvatarText>
          </ProfileWrapper>
        )}
      </Flex>
    </Wrapper>
  )
}

export default Header
