import Following from 'components/business/Main/Home/Following'
import NewAndRecommended from 'components/business/Main/Home/NewAndRecommended'
import TopBanners from 'components/business/Main/Home/TopBanners'
import UnlockAllFeaturesBanner from 'components/business/Main/Home/UnlockAllFeaturesBanner'
import Header from 'components/ui/Header'
import { ReactComponent as HomeIcon } from 'components/ui/Sidebar/icons/active/home.svg'
import { useAuth } from 'hooks/useAuth'

import { Wrapper } from '../styled'

function Home() {
  const { user } = useAuth()
  return (
    <>
      <Header icon={<HomeIcon />} title="Home" />
      <Wrapper>
        <TopBanners />
        {user && <Following />}
        <NewAndRecommended />
        {!user && <UnlockAllFeaturesBanner />}
      </Wrapper>
    </>
  )
}

export default Home
