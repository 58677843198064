import { Description, JoinButton, Title, Wrapper } from './styled'

function UnlockAllFeaturesBanner() {
  return (
    <Wrapper>
      <div>
        <Title>Unlock all features</Title>
        <Description>Join Path4Life to be able to create notes, follow series and save lectures</Description>
      </div>
      <JoinButton>JOIN PATH4LIFE</JoinButton>
    </Wrapper>
  )
}

export default UnlockAllFeaturesBanner
