import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;

  & > svg {
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  & > ${Item} {
    &:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
`

export const MainInfo = styled.div`
  display: flex;
  align-items: center;
`

export const Image = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 8px;

  object-fit: cover;
  object-position: center;

  border-radius: 12px;
`

export const TitleRow = styled.span`
  width: 218px;
  display: flex;
  align-items: center;

  margin-bottom: 6px;
`

export const Number = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: #696b7b;

  white-space: nowrap;

  margin-right: 2px;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.default};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Description = styled.p`
  width: 218px;
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.hint};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const SeriesTitle = styled.span<{ color?: string }>`
  color: ${({ color }) => color || COLORS.text.default};
`

export const PlayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  margin-right: 8px;

  background: ${COLORS.primary.dark};
  box-shadow: 0px 0px 20px rgba(42, 76, 96, 0.1);
  border-radius: 50%;

  cursor: pointer;
`

export const PlayerDescription = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`
