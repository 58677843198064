import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;

  & > div {
    width: calc(50% - 7px);
    border: 1px solid #ebebeb;
    border-radius: 12px;

    padding: 25px 24px;

    &:first-child {
      margin-right: 14px;
    }
  }
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
  color: ${COLORS.text.default};
`
