import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as CompletedIcon } from 'assets/icons/tick.svg'

import { AddButton, Description, Image, ImageContainer, Title, Wrapper } from './styled'

type Props = {
  img: string
  title?: string
  description?: string
  showAddBtn?: boolean
  selected?: boolean
  onAddBtnClick?: (selected: boolean) => void
}

function SeriesItem({ img, title, description, showAddBtn, selected, onAddBtnClick }: Props) {
  return (
    <Wrapper>
      <ImageContainer>
        <Image src={img} />
        {showAddBtn && (
          <AddButton isCompleted={selected} onClick={() => onAddBtnClick(!selected)}>
            {selected ? <CompletedIcon /> : <PlusIcon />}
          </AddButton>
        )}
      </ImageContainer>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </Wrapper>
  )
}

export default SeriesItem
