import { ReactComponent as MoreIcon } from 'assets/icons/dots.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/play-small.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'

import {
  Description,
  Image,
  Item,
  MainInfo,
  Number,
  PlayButtonWrapper,
  PlayerDescription,
  SeriesTitle,
  Title,
  TitleRow,
  Wrapper,
} from './styled'

type Props = { items: any[] }

function ShiurimList({ items }: Props) {
  return (
    <Wrapper>
      {items.map(({ img, number, title, series, seriesColor, tags, durationSeconds, date }) => (
        <Item key={number}>
          <MainInfo>
            <Image src={img} />
            <div>
              <TitleRow>
                <Number>{number}</Number>
                <Title>{title}</Title>
              </TitleRow>
              <Description>
                <SeriesTitle color={seriesColor}>{series}</SeriesTitle>
                {tags?.length ? `∙${tags.join('∙')}` : ''}
              </Description>
            </div>
          </MainInfo>
          <Flex alignCenter>
            <PlayButtonWrapper>
              <PlayIcon />
            </PlayButtonWrapper>
            <PlayerDescription>
              {date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} ·{' '}
              {dayjs.duration(durationSeconds, 'seconds').format('m:ss')} min
            </PlayerDescription>
          </Flex>
          <MoreIcon />
        </Item>
      ))}
    </Wrapper>
  )
}

export default ShiurimList
