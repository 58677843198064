import { Flex } from 'components/common/Flex'

import { TitleDivider, ViewAllLink } from '../Following/styled'
import { mockData } from '../NewAndRecommended/mockData'
import { Title } from '../NewAndRecommended/styled'
import ShiurimList from '../ShiurimList'

function Recommended() {
  return (
    <div>
      <Flex alignCenter>
        <Title>Recommended</Title>
        <TitleDivider />
        <ViewAllLink to="/recommended">View all</ViewAllLink>
      </Flex>
      <ShiurimList items={mockData} />
    </div>
  )
}

export default Recommended
