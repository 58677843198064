import { ReactComponent as NextIcon } from 'assets/icons/slider-arrow-next.svg'
import { ReactComponent as PreviousIcon } from 'assets/icons/slider-arrow-previous.svg'
import { Flex } from 'components/common/Flex'
import { Ref, useRef, useState } from 'react'
import { Swiper, SwiperClass, SwiperRef, SwiperSlide } from 'swiper/react'

import ShiurimCard from '../ShiurimCard'
import { mockData } from './mockData'
import { ButtonsContainer, ListWrapper, SliderButton, Title, TitleDivider, ViewAllLink, Wrapper } from './styled'

function Following() {
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)
  const swiperRef: Ref<SwiperRef> = useRef()

  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) setIsPrevBtnDisabled(true)
    if (swiper.isEnd) setIsNextBtnDisabled(true)

    if (!swiper.isBeginning) setIsPrevBtnDisabled(false)
    if (!swiper.isEnd) setIsNextBtnDisabled(false)
  }
  return (
    <Wrapper>
      <Flex justifyBetween alignCenter>
        <Flex alignCenter>
          <Title>Following</Title>
          <TitleDivider />
          <ViewAllLink to="/following">View all</ViewAllLink>
        </Flex>
        <ButtonsContainer>
          <SliderButton disabled={isPrevBtnDisabled} onClick={() => swiperRef.current.swiper.slidePrev()}>
            <PreviousIcon />
          </SliderButton>
          <SliderButton disabled={isNextBtnDisabled} onClick={() => swiperRef.current.swiper.slideNext()}>
            <NextIcon />
          </SliderButton>
        </ButtonsContainer>
      </Flex>
      <ListWrapper>
        <Swiper ref={swiperRef} slidesPerGroup={4} slidesPerView={4} spaceBetween={14} onSlideChange={onSlideChange}>
          {mockData.map(({ img, number, title, subtitle, date, durationSeconds }) => (
            <SwiperSlide key={date.toISOString()}>
              <ShiurimCard
                img={img}
                number={number}
                title={title}
                subtitle={subtitle}
                date={date}
                durationSeconds={durationSeconds}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </ListWrapper>
    </Wrapper>
  )
}

export default Following
