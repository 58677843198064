import { Button } from 'antd'
import backgroundSrc from 'assets/img/unlock-all-features-background.png'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  padding: 40px;
  margin-top: 40px;

  background-image: url(${backgroundSrc});
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: #ffffff;

  margin-bottom: 8px;
`

export const Description = styled.p`
  font-size: 20px;
  line-height: 1.2;
  color: rgba(255, 255, 255, 0.8);
`

export const JoinButton = styled(Button)`
  width: 178px;
  height: 42px;

  font-size: 14px;
  line-height: 1;

  letter-spacing: 0.02em;
`
