import { useEffect, useState } from 'react'

import { Content, Description, Header, Title, Wrapper } from './styled'

function Sponsor() {
  const [currentSponsorIndex, setCurrentSponsorIndex] = useState(0)
  const sponsors = [
    { type: 'DAILY', inHonourOf: 'Miriam Weisman' },
    { type: 'WEEKLY', name: 'Maksym Karpets', inHonourOf: 'Miriam Weisman' },
    { type: 'MONTHLY' },
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentSponsorIndex === sponsors.length - 1) {
        return setCurrentSponsorIndex(0)
      }

      setCurrentSponsorIndex((prev) => prev + 1)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [currentSponsorIndex, sponsors.length])

  return (
    <Wrapper>
      <Header>{sponsors[currentSponsorIndex].type} SPONSOR</Header>
      <Content>
        <Title>{sponsors[currentSponsorIndex].name || 'Anonymous'}</Title>
        {sponsors[currentSponsorIndex].inHonourOf && (
          <Description>In honour of {sponsors[currentSponsorIndex].inHonourOf}</Description>
        )}
      </Content>
    </Wrapper>
  )
}

export default Sponsor
