import { Form as ANTDForm, Button } from 'antd'
import backgroundImgSrc from 'assets/img/auth-background.png'
import { COLORS } from 'constants/colors'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;

  padding: 32px;

  background-color: #ffffff;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`

export const Logo = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;

  cursor: pointer;
`

export const Image = styled.img`
  width: 50%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`

export const Content = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;

  padding: 0px 20px;

  background-color: #ffffff;
`

// Full width pages layout components

export const FullWidthPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  background-image: url(${backgroundImgSrc});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
`

export const FullWidthPageHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 28px;

  border-bottom: 0.5px solid #ebebeb;
`

export const FullWidthPageFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 22px 32px;

  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
`

export const FullWidthPageFooterButton = styled(Button)`
  width: 160px;
`

export const FullWidthPageContainer = styled.div`
  overflow: auto;
  display: grid;
  justify-items: center;
  align-items: center;

  padding: 20px 0;

  height: 100%;
`

export const FullWidthPageContent = styled.div<{ big?: boolean; medium?: boolean }>`
  display: grid;
  align-items: center;
  justify-items: center;

  width: 100%;
  max-width: ${({ big, medium }) => (big ? '472px' : medium ? '450px' : '400px')};
`

// Reusable components for Login and Registration and Forgot Password

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  max-width: 400px;
`

export const Heading = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  color: ${COLORS.text.default};

  margin-bottom: 12px;
`

export const Description = styled.h2`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;

  margin-bottom: 32px;

  color: ${COLORS.text.hint};
`

export const Link = styled(RouterLink)`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;

  vertical-align: baseline;
  align-self: flex-start;
  margin-top: 10px;

  text-decoration: none;

  color: ${COLORS.primary.medium};
  &:active {
    color: ${COLORS.primary.medium};
  }
`

export const FakeLink = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: end;
  color: ${COLORS.primary.medium};

  margin-top: 8px;
  margin-bottom: 0;

  cursor: pointer;
`

export const Form = styled(ANTDForm)`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const FormItem = styled(ANTDForm.Item)`
  &:not(:first-child) {
    margin-top: 16px;
  }
  margin-bottom: 0;
`

export const SubmitButton = styled(Button)`
  width: 100%;

  margin-top: 24px;
`

export const IconButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AppleButton = styled(IconButton)`
  margin-bottom: 12px;

  background-color: #000000;

  &.ant-btn-primary:not(:disabled):hover {
    background-color: #333333;
  }
`

export const Footer = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;

  padding: 28px 0px 0px 0px;
  margin-top: 20px;

  text-align: center;

  border-top: 1px solid #ebebeb;
`

export const DividerWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 24px 0px;
`

export const DividerText = styled.p`
  margin: 0px 10px;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ebebeb;
`

export const ErrorText = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: #e23352;

  margin-top: 12px;
`
