/* eslint-disable react/no-unescaped-entities */
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import notificationsPreviewImageSrc from 'assets/img/notifications-preview.jpg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { FullWidthPageContainer, FullWidthPageContent, FullWidthPageHeader, FullWidthPageWrapper } from '../styled'
import { Description, Image, LaterButton, SubmitButton, Title } from './styled'

function EmailNotifications() {
  const navigate = useNavigate()

  const onEnable = () => {
    console.log('Enable notifications!')
    navigate(ROUTE_PATH.HOME)
  }

  const onLater = () => {
    console.log('Maybe later')
    navigate(ROUTE_PATH.HOME)
  }
  return (
    <FullWidthPageWrapper>
      <FullWidthPageHeader>
        <Logo />
      </FullWidthPageHeader>
      <FullWidthPageContainer>
        <FullWidthPageContent big>
          <Image width={450} height={314} src={notificationsPreviewImageSrc} />
          <Title>Turn on email notification</Title>
          <Description>
            Don't miss out on anything important.
            <br />
            Allow Path4Life to send you notifications
          </Description>
          <SubmitButton type="primary" onClick={onEnable}>
            TURN ON
          </SubmitButton>
          <LaterButton onClick={onLater}>Maybe later</LaterButton>
        </FullWidthPageContent>
      </FullWidthPageContainer>
    </FullWidthPageWrapper>
  )
}

export default EmailNotifications
