import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 136px;
  text-align: center;
`

export const ImageContainer = styled.div`
  position: relative;
`

export const Image = styled.img`
  width: 136px;
  height: 136px;

  object-fit: cover;
  object-position: center;

  border-radius: 50%;
`

export const AddButton = styled.div<{ isCompleted?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  right: 8px;
  width: 32px;
  height: 32px;

  background: ${({ isCompleted }) => (isCompleted ? '#EAF7FE' : COLORS.primary.medium)};

  border: 2px solid #ffffff;
  border-radius: 16px;

  cursor: pointer;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: ${COLORS.text.default};

  margin-top: 8px;
  margin-bottom: 2px;
`

export const Description = styled.p`
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  color: ${COLORS.text.hint};
`
