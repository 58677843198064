import authBannerSrc from 'assets/img/auth-banner.svg'
import logoSrc from 'assets/img/logo.svg'
import Loader from 'components/ui/Loader'
import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { Container, Content, Image, Logo, Wrapper } from './styled'

function Auth() {
  const { isLoading, user } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (!isLoading && user) {
      navigate(ROUTE_PATH.HOME)
    }
  }, [isLoading, user])

  if (!user && !isLoading) {
    if (pathname !== '/registration' && pathname !== '/login') return <Outlet />

    return (
      <Wrapper>
        <Container>
          <Logo src={logoSrc} onClick={() => navigate(ROUTE_PATH.HOME)} />
          <Content>
            <Outlet />
          </Content>
          <Image src={authBannerSrc} />
        </Container>
      </Wrapper>
    )
  }

  return <Loader />
}

export default Auth
