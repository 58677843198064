import { Button } from 'antd'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as BookmarksActive } from './icons/active/bookmarks.svg'
import { ReactComponent as BrowseActive } from './icons/active/browse.svg'
import { ReactComponent as CommunityActive } from './icons/active/community.svg'
import { ReactComponent as DonateActive } from './icons/active/donate.svg'
import { ReactComponent as FollowSeriesActive } from './icons/active/follow.svg'
import { ReactComponent as HistoryActive } from './icons/active/history.svg'
import { ReactComponent as HomeActive } from './icons/active/home.svg'
import { ReactComponent as NotesActive } from './icons/active/notes.svg'
import { ReactComponent as SavedShiurimActive } from './icons/active/saved.svg'
import { ReactComponent as AppStoreIcon } from './icons/appstore.svg'
import { ReactComponent as Bookmarks } from './icons/inactive/bookmarks.svg'
import { ReactComponent as Browse } from './icons/inactive/browse.svg'
import { ReactComponent as Community } from './icons/inactive/community.svg'
import { ReactComponent as Donate } from './icons/inactive/donate.svg'
import { ReactComponent as FollowSeries } from './icons/inactive/follow.svg'
import { ReactComponent as History } from './icons/inactive/history.svg'
import { ReactComponent as Home } from './icons/inactive/home.svg'
import { ReactComponent as Notes } from './icons/inactive/notes.svg'
import { ReactComponent as SavedShiurim } from './icons/inactive/saved.svg'
import { ReactComponent as PlayMarketIcon } from './icons/playmarket.svg'
import { ButtonsContainer, Divider, Label, LogoLink, Menu, MenuItem, Sider } from './styled'

enum MenuKeys {
  HOME = '/home',
  BROWSE = '/browse',
  NOTES = '/notes',
  BOOKMARKS = '/bookmarks',
  SAVED_SHIURIM = '/saved-shiurim',
  FOLLOWED_SERIES = '/followed-series',
  HISTORY = '/history',
  COMMUNITY = '/community',
  DONATE = '/donate',
}

function SideBar() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [activeMenu, changeMenu] = useState<MenuKeys | string>(MenuKeys.HOME)

  useEffect(() => {
    const pathnameArray = pathname.split('/')
    switch (pathnameArray[1]) {
      case 'home':
        return changeMenu(MenuKeys.HOME)
      case 'browse':
        return changeMenu(MenuKeys.BROWSE)
      default:
        return changeMenu(MenuKeys.HOME)
    }
  }, [pathname])

  function onClickMenu({ key }: MenuInfo) {
    navigate(key)
    changeMenu(key)
  }
  return (
    <Sider width={246}>
      <LogoLink to={MenuKeys.HOME}>
        <Logo />
      </LogoLink>
      <Menu mode="inline" selectedKeys={[activeMenu]} onClick={onClickMenu}>
        <MenuItem key={MenuKeys.HOME} icon={activeMenu === MenuKeys.HOME ? <HomeActive /> : <Home />}>
          Home
        </MenuItem>
        <MenuItem key={MenuKeys.BROWSE} icon={activeMenu === MenuKeys.BROWSE ? <BrowseActive /> : <Browse />}>
          Browse
        </MenuItem>
        <Divider />
        <Label>HIGHLIGHTS</Label>
        <MenuItem key={MenuKeys.NOTES} icon={activeMenu === MenuKeys.NOTES ? <NotesActive /> : <Notes />}>
          Notes
        </MenuItem>
        <MenuItem
          key={MenuKeys.BOOKMARKS}
          icon={activeMenu === MenuKeys.BOOKMARKS ? <BookmarksActive /> : <Bookmarks />}
        >
          Bookmarks
        </MenuItem>
        <MenuItem
          key={MenuKeys.SAVED_SHIURIM}
          icon={activeMenu === MenuKeys.SAVED_SHIURIM ? <SavedShiurimActive /> : <SavedShiurim />}
        >
          Saved shiurim
        </MenuItem>
        <MenuItem
          key={MenuKeys.FOLLOWED_SERIES}
          icon={activeMenu === MenuKeys.FOLLOWED_SERIES ? <FollowSeriesActive /> : <FollowSeries />}
        >
          Followed series
        </MenuItem>
        <MenuItem key={MenuKeys.HISTORY} icon={activeMenu === MenuKeys.HISTORY ? <HistoryActive /> : <History />}>
          History
        </MenuItem>
        <Divider bottom />
        <MenuItem
          key={MenuKeys.COMMUNITY}
          icon={activeMenu === MenuKeys.COMMUNITY ? <CommunityActive /> : <Community />}
        >
          Community
        </MenuItem>
        <MenuItem key={MenuKeys.DONATE} icon={activeMenu === MenuKeys.DONATE ? <DonateActive /> : <Donate />}>
          Donate
        </MenuItem>
        <Label bottom>GET THE APP</Label>
        <ButtonsContainer>
          <Button>
            <AppStoreIcon />
          </Button>
          <Button>
            <PlayMarketIcon />
          </Button>
        </ButtonsContainer>
      </Menu>
    </Sider>
  )
}
export default SideBar
