export const mockData = [
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    series: 'Turning from Evil, and Doing Good',
    seriesColor: '#A347A5',
    tags: ['Synopsis', 'Something else', 'Tisha Bav', 'Kesubos'],
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    series: 'Turning from Evil, and Doing Good',
    seriesColor: '#A347A5',
    tags: ['Synopsis', 'Something else', 'Tisha Bav', 'Kesubos'],
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    series: 'Turning from Evil, and Doing Good',
    seriesColor: '#A347A5',
    tags: ['Synopsis', 'Something else', 'Tisha Bav', 'Kesubos'],
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    series: 'Turning from Evil, and Doing Good',
    seriesColor: '#A347A5',
    tags: ['Synopsis', 'Something else', 'Tisha Bav', 'Kesubos'],
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    series: 'Turning from Evil, and Doing Good',
    seriesColor: '#A347A5',
    tags: ['Synopsis', 'Something else', 'Tisha Bav', 'Kesubos'],
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    series: 'Turning from Evil, and Doing Good',
    seriesColor: '#A347A5',
    tags: ['Synopsis', 'Something else', 'Tisha Bav', 'Kesubos'],
    date: new Date(),
    durationSeconds: 90,
  },
]
