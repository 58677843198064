import { Input, Layout, Popover, PopoverProps } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

const MyPopover = ({ className, ...props }: PopoverProps) => <Popover {...props} overlayClassName={className} />

export const Wrapper = styled(Layout.Header)`
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 28px 20px 28px;

  background-color: #ffffff;
`

export const IconWrapper = styled.div<{ clickable?: boolean }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 12px;

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 12px;

  ${({ clickable }) => clickable && 'cursor: pointer'};
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  color: #0f1653;
`

export const Search = styled(Input)`
  width: 100%;
  max-width: 536px;
  margin-left: 40px;

  background: #f4fbff;
`

export const NotificationWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 12px;

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 50%;

  cursor: pointer;
`

export const NotificationsPopover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0;
    border: 1px solid #d9ecf6;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const AvatarPopover = styled(MyPopover)`
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    width: 240px;
    padding: 0;
    border: 1px solid #ebebeb;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }
`

export const AvatarPopoverItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  transition: all 0.2s;

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }

  &:hover {
    color: ${COLORS.primary.medium};
  }

  cursor: pointer;
`

export const AvatarPopoverText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  margin-left: 10px;
`

export const ProfileWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  padding: 4px 12px 4px 4px;

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 40px;

  cursor: pointer;
`

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;

  object-fit: cover;
  object-position: center;

  border: 1px solid #ffffff;
  border-radius: 50%;
`

export const AvatarText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  color: #0082cd;
`
