import { Auth } from '@aws-amplify/auth'
import { Amplify } from '@aws-amplify/core'

Amplify.configure({
  Auth: {
    // identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
    endpoint: '',
  },
})

Auth.configure({
  // identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID,
})

Amplify.register(Auth)
