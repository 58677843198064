import { COLORS } from 'constants/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 274px;
  padding: 16px;

  border: 1px solid #ebebeb;
  border-radius: 12px;
`

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 12px;
`

export const Image = styled.img`
  width: 242px;
  height: 242px;

  object-fit: cover;
  object-position: center;

  border-radius: 12px;
`

export const PlayerController = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background: #ffffff;

  cursor: pointer;
`

export const TitleRow = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 6px;
`

export const Number = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.hint};

  margin-right: 2px;
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  color: ${COLORS.text.default};
`

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.hint};

  margin-bottom: 12px;
`

export const MetaInfo = styled.p`
  font-size: 14px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`
