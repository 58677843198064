import AuthRoot from 'pages/Auth'
import AccountSetup from 'pages/Auth/AccountSetup'
import EmailNotifications from 'pages/Auth/EmailNotifications'
import FollowSeries from 'pages/Auth/FollowSeries'
import ForgotPassword from 'pages/Auth/ForgotPassword'
import Login from 'pages/Auth/Login'
import Registration from 'pages/Auth/Registration'
import VerifyEmail from 'pages/Auth/VerifyEmail'
import Main from 'pages/Main'
import Home from 'pages/Main/Home'
import { createBrowserRouter } from 'react-router-dom'

export const ROUTE_PATH = {
  REGISTRATION: '/registration',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  ACCOUNT_SETUP: '/account-setup',
  FOLLOW_SERIES: '/follow-series',
  NOTIFICATIONS_SETUP: '/notifications-setup',
  HOME: '/home',
}

const router = createBrowserRouter([
  {
    element: <AuthRoot />,
    children: [
      { path: '/registration', element: <Registration /> },
      { path: '/login', element: <Login /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/verify-email', element: <VerifyEmail /> },
      { path: '/account-setup', element: <AccountSetup /> },
      { path: '/follow-series', element: <FollowSeries /> },
      { path: '/notifications-setup', element: <EmailNotifications /> },
    ],
  },
  {
    path: '/',
    element: <Main />,
    children: [{ path: '/home', element: <Home /> }],
  },
])

export default router
