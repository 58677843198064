import Loader from 'components/ui/Loader'
import SideBar from 'components/ui/Sidebar'
import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { Content, MainWrapper } from './styled'

function Main() {
  const { isLoading } = useAuth()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === '/') navigate(ROUTE_PATH.HOME)
  }, [navigate, pathname])

  if (isLoading) return <Loader />

  return (
    <MainWrapper hasSider>
      <SideBar />
      <Content>
        <Outlet />
      </Content>
    </MainWrapper>
  )
}

export default Main
