import { Form as ANTDForm, Input } from 'antd'
import { ReactComponent as AppleIcon } from 'assets/icons/apple.svg'
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg'
import VerificationCodeSentModal from 'components/business/Auth/VerificationCodeSentModal'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import {
  AppleButton,
  ContentContainer,
  Description,
  Divider,
  DividerText,
  DividerWrapper,
  ErrorText,
  Footer,
  Form,
  FormItem,
  Heading,
  IconButton,
  Link,
  SubmitButton,
} from '../styled'

const { Password } = Input

function Registration() {
  const { signUp, signInWith } = useAuth()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isAppleLoading, setIsAppleLoading] = useState(false)
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const [error, setError] = useState('')
  const [isErrors, setIsErrors] = useState(false)
  const [isVerificationCodeModalOpen, setIsVerificationCodeModalOpen] = useState(false)

  const [form] = Form.useForm()
  const email = Form.useWatch('email', { form })
  const password = Form.useWatch('password', { form })
  const confirmPassword = Form.useWatch('confirm-password', { form })

  useEffect(() => {
    if (email && password && confirmPassword) {
      form
        .validateFields()
        .then(() => setIsErrors(false))
        .catch(() => {
          setIsErrors(true)
        })
    }
  }, [email, password, confirmPassword, form])

  const onSignUp = async () => {
    setIsLoading(true)
    setError('')
    try {
      await signUp(email, password)

      setIsVerificationCodeModalOpen(true)
    } catch (e) {
      setError(e.message)
    }
    setIsLoading(false)
  }

  const onSignUpWithApple = async () => {
    setIsAppleLoading(true)
    setError('')
    try {
      await signInWith(CognitoHostedUIIdentityProvider.Apple)
    } catch (e) {
      setError(e.message)
    }
    setIsAppleLoading(false)
  }

  const onSignUpWithGoogle = async () => {
    setIsGoogleLoading(true)
    setError('')
    try {
      await signInWith(CognitoHostedUIIdentityProvider.Google)
    } catch (e) {
      setError(e.message)
    }
    setIsGoogleLoading(false)
  }

  const onReceiveVerificationCode = () => {
    navigate(ROUTE_PATH.VERIFY_EMAIL, { state: { email } })
  }

  const onCloseModal = () => setIsVerificationCodeModalOpen(false)

  const isDisabled = !email || !password || !confirmPassword || isErrors

  return (
    <>
      <ContentContainer>
        <Heading>Register</Heading>
        <Description>Complete the details below to create an account</Description>
        <Form form={form} layout="vertical" hideRequiredMark onValuesChange={() => setError('')}>
          <FormItem
            name="email"
            label="Email"
            required
            rules={[
              { required: true, message: '' },
              {
                type: 'email',
                message: 'Incorrect email',
              },
            ]}
          >
            <Input placeholder="Enter your email" type="email" status={error ? 'error' : undefined} />
          </FormItem>
          <FormItem
            name="password"
            label="Password"
            required
            rules={[
              {
                required: true,
                message: '',
              },
              {
                min: 8,
                max: 20,
                message: 'Incorrect password',
              },
            ]}
          >
            <Password
              placeholder="Create password (at least 8 characters)"
              type="password"
              status={error ? 'error' : undefined}
            />
          </FormItem>
          <FormItem
            name="confirm-password"
            label="Confirm password"
            required
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject({ message: 'The two passwords that you entered do not match!' })
                },
              }),
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Password placeholder="Confirm your password" type="password" status={error ? 'error' : undefined} />
          </FormItem>
          {error && <ErrorText>{error}</ErrorText>}
          <ANTDForm.Item noStyle>
            <SubmitButton type="primary" disabled={isDisabled} loading={isLoading} onClick={onSignUp}>
              REGISTER
            </SubmitButton>
          </ANTDForm.Item>
          <DividerWrapper>
            <Divider />
            <DividerText>or</DividerText>
            <Divider />
          </DividerWrapper>
          <AppleButton type="primary" icon={<AppleIcon />} loading={isAppleLoading} onClick={onSignUpWithApple}>
            REGISTER WITH APPLE
          </AppleButton>
          <IconButton icon={<GoogleIcon />} loading={isGoogleLoading} onClick={onSignUpWithGoogle}>
            REGISTER WITH GOOGLE
          </IconButton>
        </Form>
      </ContentContainer>
      <Footer>
        Already have an account? <Link to="/login">Log in</Link>
      </Footer>
      <VerificationCodeSentModal
        open={isVerificationCodeModalOpen}
        onClose={onCloseModal}
        onComplete={onReceiveVerificationCode}
      />
    </>
  )
}

export default Registration
