import { Input, Radio, UploadFile, UploadProps } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import { ReactComponent as RightArrowIcon } from 'assets/icons/right-arrow.svg'
import { ReactComponent as AvatarIcon } from 'assets/icons/user.svg'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import TextArea from 'components/ui/FormElements/TextArea'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import {
  FullWidthPageContainer,
  FullWidthPageContent,
  FullWidthPageFooter,
  FullWidthPageFooterButton,
  FullWidthPageHeader,
  FullWidthPageWrapper,
} from '../styled'
import LocationModal from './LocationModal'
import {
  AvatarImage,
  DescriptionSubtitle,
  DescriptionTitle,
  DescriptionWrapper,
  Form,
  FormItem,
  Header,
  RadioGroup,
  Upload,
  UploadLabel,
} from './styled'

function AccountSetup() {
  const navigate = useNavigate()

  const [isErrors, setIsErrors] = useState(false)
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const uploadRef = useRef(null)

  const [form] = useForm()
  const fullName = useWatch('fullName', { form })
  const gender = useWatch('gender', { form })
  const biography = useWatch('biography', { form })
  const country = useWatch('country', { form })
  const city = useWatch('city', { form })

  useEffect(() => {
    if (fullName) {
      form
        .validateFields()
        .then(() => setIsErrors(false))
        .catch(() => {
          setIsErrors(true)
        })
    }
  }, [fullName, form])

  const onChangeAvatar: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)

  const onLocationInputClick = () => setIsLocationModalOpen(true)
  const onCloseLocationModal = () => setIsLocationModalOpen(false)

  const onSelectLocation = (country: string) => {
    form.setFieldValue('country', country)
    onCloseLocationModal()
  }

  const onSubmit = () => {
    console.log(fileList?.[0]?.originFileObj, fullName, gender, biography, country, city)
    navigate(ROUTE_PATH.FOLLOW_SERIES)
  }

  const preview = fileList.length ? URL.createObjectURL(fileList[0].originFileObj) : null
  const isContinueButtonDisabled = !fullName || !gender || isErrors
  return (
    <>
      <FullWidthPageWrapper>
        <FullWidthPageHeader>
          <Logo />
        </FullWidthPageHeader>
        <FullWidthPageContainer>
          <FullWidthPageContent>
            <Header>Account setup</Header>
            <DescriptionWrapper>
              <DescriptionTitle>Tell us about yourself!</DescriptionTitle>
              <DescriptionSubtitle>
                All information will stay private and not be
                <br />
                shared with any other users or outside sources
              </DescriptionSubtitle>
            </DescriptionWrapper>
            <Upload
              ref={uploadRef}
              customRequest={() => null}
              maxCount={1}
              showUploadList={false}
              accept="image/jpg, image/jpeg, image/png"
              listType="picture-circle"
              fileList={fileList}
              onChange={onChangeAvatar}
            >
              {fileList.length ? <AvatarImage src={preview} /> : <AvatarIcon />}
            </Upload>
            <UploadLabel onClick={() => uploadRef.current.upload.uploader.onClick()}>
              Upload profile image (optional)
            </UploadLabel>
            <Form form={form} layout="vertical">
              <FormItem
                name="fullName"
                label="Full name"
                required={false}
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  { pattern: new RegExp('^[-\\sa-zA-Z]+$'), message: 'Incorrect full name' },
                ]}
              >
                <Input placeholder="Enter your full name" maxLength={100} />
              </FormItem>
              <FormItem name="gender" label="Select how to address">
                <RadioGroup>
                  <Radio.Button value="male">Mr.</Radio.Button>
                  <Radio.Button value="female">Mrs.</Radio.Button>
                </RadioGroup>
              </FormItem>
              <FormItem name="biography" label="Bio (optional)">
                <TextArea maxLength={180} showCount rows={4} />
              </FormItem>
              <FormItem name="country" label="Country (optional)" isButton>
                <div onClick={onLocationInputClick}>
                  <Input placeholder="Select country" suffix={<RightArrowIcon />} value={country} disabled />
                </div>
              </FormItem>
              {country && (
                <FormItem name="city" label="City (optional)">
                  <Input maxLength={100} placeholder="Enter city" />
                </FormItem>
              )}
            </Form>
          </FullWidthPageContent>
        </FullWidthPageContainer>
        <FullWidthPageFooter>
          <FullWidthPageFooterButton type="primary" disabled={isContinueButtonDisabled} onClick={onSubmit}>
            CONTINUE
          </FullWidthPageFooterButton>
        </FullWidthPageFooter>
      </FullWidthPageWrapper>
      {isLocationModalOpen && (
        <LocationModal open={isLocationModalOpen} onClose={onCloseLocationModal} onSelect={onSelectLocation} />
      )}
    </>
  )
}

export default AccountSetup
