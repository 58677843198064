export const mockData = [
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
  {
    img: 'https://xms-production-f.squarecdn.com/xms/assets/public-web-styles/social/default-56f973ec4d9cb2927e20a0cb97201783e27bf352585fa25cddbde11aa81635a7.jpg',
    number: 'Mitzvah 31.',
    title: 'Appreciating Pain',
    subtitle: 'Turning from Evil, and Doing Good',
    date: new Date(),
    durationSeconds: 90,
  },
]
