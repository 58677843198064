import NewShiurim from '../NewSiurim'
import Recommended from '../Recommended'
import { Wrapper } from './styled'

function NewAndRecommended() {
  return (
    <Wrapper>
      <NewShiurim />
      <Recommended />
    </Wrapper>
  )
}

export default NewAndRecommended
