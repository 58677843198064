import { Form as ANTDForm, Input } from 'antd'
import { ReactComponent as AppleIcon } from 'assets/icons/apple.svg'
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg'
import ForgotPasswordModal from 'components/business/Auth/ForgotPasswordModal'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import {
  AppleButton,
  ContentContainer,
  Description,
  Divider,
  DividerText,
  DividerWrapper,
  ErrorText,
  FakeLink,
  Footer,
  Form,
  FormItem,
  Heading,
  IconButton,
  Link,
  SubmitButton,
} from '../styled'

const { Password } = Input

function Login() {
  const { signIn, signInWith } = useAuth()
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isAppleLoading, setIsAppleLoading] = useState(false)
  const [isGoogleLoading, setIsGoogleLoading] = useState(false)
  const [error, setError] = useState('')
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false)

  const email = Form.useWatch('email', { form })
  const password = Form.useWatch('password', { form })

  const isDisabled = !email || !password

  const onSignIn = async () => {
    setIsLoading(true)
    setError('')
    try {
      await signIn(email, password)
      navigate(ROUTE_PATH.HOME)
    } catch (e) {
      setError(e.message)
    }
    setIsLoading(false)
  }

  const onSignInWithApple = async () => {
    setIsAppleLoading(true)
    setError('')
    try {
      await signInWith(CognitoHostedUIIdentityProvider.Apple)
      navigate(ROUTE_PATH.HOME)
    } catch (e) {
      setError(e.message)
    }
    setIsAppleLoading(false)
  }

  const onSignInWithGoogle = async () => {
    setIsGoogleLoading(true)
    setError('')
    try {
      await signInWith(CognitoHostedUIIdentityProvider.Google)
      navigate(ROUTE_PATH.HOME)
    } catch (e) {
      setError(e.message)
    }
    setIsGoogleLoading(false)
  }

  const onForgotPasswordClick = () => setIsForgotPasswordModalOpen(true)

  const onCloseModal = () => setIsForgotPasswordModalOpen(false)

  return (
    <>
      <ContentContainer>
        <Heading>Log in</Heading>
        <Description>Complete the details below to log in</Description>
        <Form form={form} layout="vertical" onValuesChange={() => setError('')}>
          <FormItem
            name="email"
            label="Email"
            required={false}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Input placeholder="Enter your email" type="email" status={error ? 'error' : undefined} />
          </FormItem>
          <FormItem
            name="password"
            label="Password"
            required={false}
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <Password placeholder="Enter password" type="password" status={error ? 'error' : undefined} />
          </FormItem>
          {error && <ErrorText>{error}</ErrorText>}
          <FakeLink onClick={onForgotPasswordClick}>Forgot password?</FakeLink>
          <ANTDForm.Item noStyle>
            <SubmitButton type="primary" disabled={isDisabled} loading={isLoading} onClick={onSignIn}>
              LOG IN
            </SubmitButton>
          </ANTDForm.Item>
          <DividerWrapper>
            <Divider />
            <DividerText>or</DividerText>
            <Divider />
          </DividerWrapper>
          <AppleButton type="primary" icon={<AppleIcon />} loading={isAppleLoading} onClick={onSignInWithApple}>
            LOG IN WITH APPLE
          </AppleButton>
          <IconButton icon={<GoogleIcon />} loading={isGoogleLoading} onClick={onSignInWithGoogle}>
            LOG IN WITH GOOGLE
          </IconButton>
        </Form>
      </ContentContainer>
      <Footer>
        Don’t have an account? <Link to="/registration">Register</Link>
      </Footer>
      {isForgotPasswordModalOpen && <ForgotPasswordModal open={isForgotPasswordModalOpen} onClose={onCloseModal} />}
    </>
  )
}

export default Login
