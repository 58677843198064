import { Layout } from 'antd'
import styled from 'styled-components'

export const MainWrapper = styled(Layout)`
  height: 100vh;
  background-color: #f4fbff;
`

export const Content = styled(Layout.Content)`
  overflow: auto;
  width: 100%;
  background-color: #ffffff;
  box-shadow: -20px 0px 80px rgba(185, 208, 236, 0.13);
  border: 1px solid #eaf7fe;
  border-radius: 24px 0px 0px 24px;
  z-index: 1;
`

export const Wrapper = styled.div`
  overflow: auto;
  height: calc(100% - 88px); // full height - header height
  padding: 8px 27px 40px;
`
