import { COLORS } from 'constants/colors'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 40px;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  color: ${COLORS.text.default};
`

export const TitleDivider = styled.div`
  width: 1px;
  height: 24px;
  margin: 0 12px;
  background: #d9ecf6;

  border-radius: 1px;
`

export const ViewAllLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${COLORS.primary.medium};
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 8px;
  }
`

export const SliderButton = styled.div<{ disabled?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #f4fbff;
  border: 0.5px solid #d9ecf6;
  border-radius: 100px;

  ${({ disabled }) => !disabled && 'cursor: pointer'};

  ${({ disabled }) => disabled && 'opacity: 0.5'};
`

export const ListWrapper = styled.div`
  height: 360px;
  margin-top: 20px;
`
