import { ReactComponent as Logo } from 'assets/img/logo.svg'
import SeriesItem from 'components/business/Auth/SeriesItem'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import {
  FullWidthPageContainer,
  FullWidthPageContent,
  FullWidthPageFooter,
  FullWidthPageFooterButton,
  FullWidthPageHeader,
  FullWidthPageWrapper,
} from '../styled'
import { mockData } from './mockData'
import { Header, List } from './styled'

function FollowSeries() {
  const navigate = useNavigate()
  const [selectedArray, setSelectedArray] = useState([])
  const onSubmit = () => {
    navigate(ROUTE_PATH.NOTIFICATIONS_SETUP)
    console.log('Submit')
  }

  const onAddBtnClick = (id: string, selected: boolean) => {
    if (selected) {
      return setSelectedArray((prev) => [...prev, id])
    }
    setSelectedArray((prev) => prev.filter((el) => el !== id))
  }

  const isContinueButtonDisabled = !selectedArray.length
  return (
    <FullWidthPageWrapper>
      <FullWidthPageHeader>
        <Logo />
      </FullWidthPageHeader>
      <FullWidthPageContainer>
        <FullWidthPageContent big>
          <Header>Follow series</Header>
          <List>
            {mockData.map(({ id, ...props }) => (
              <SeriesItem
                key={id}
                onAddBtnClick={(selected) => onAddBtnClick(id, selected)}
                selected={selectedArray.includes(id)}
                showAddBtn
                {...props}
              />
            ))}
          </List>
        </FullWidthPageContent>
      </FullWidthPageContainer>
      <FullWidthPageFooter>
        <FullWidthPageFooterButton type="primary" disabled={isContinueButtonDisabled} onClick={onSubmit}>
          CONTINUE
        </FullWidthPageFooterButton>
      </FullWidthPageFooter>
    </FullWidthPageWrapper>
  )
}

export default FollowSeries
