import { ReactComponent as NextIcon } from 'assets/icons/slider-arrow-next-small.svg'
import { ReactComponent as PreviousIcon } from 'assets/icons/slider-arrow-previous-small.svg'
import { Flex } from 'components/common/Flex'
import { Ref, useRef, useState } from 'react'
import { SwiperClass, SwiperRef } from 'swiper/react'

import Calendar from '../Calendar'
import { ButtonsContainer, TitleDivider, ViewAllLink } from '../Following/styled'
import { mockData } from '../NewAndRecommended/mockData'
import { Title } from '../NewAndRecommended/styled'
import ShiurimList from '../ShiurimList'
import { SliderButton } from './styled'

function NewShiurim() {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)
  const swiperRef: Ref<SwiperRef> = useRef()

  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) setIsPrevBtnDisabled(true)
    if (swiper.isEnd) setIsNextBtnDisabled(true)

    if (!swiper.isBeginning) setIsPrevBtnDisabled(false)
    if (!swiper.isEnd) setIsNextBtnDisabled(false)
  }

  return (
    <div>
      <Flex justifyBetween alignCenter>
        <Flex alignCenter>
          <Title>New Shiurim</Title>
          <TitleDivider />
          <ViewAllLink to="/new-shiurim">View all</ViewAllLink>
        </Flex>
        <ButtonsContainer>
          <SliderButton disabled={isPrevBtnDisabled} onClick={() => swiperRef.current.swiper.slidePrev()}>
            <PreviousIcon />
          </SliderButton>
          <SliderButton disabled={isNextBtnDisabled} onClick={() => swiperRef.current.swiper.slideNext()}>
            <NextIcon />
          </SliderButton>
        </ButtonsContainer>
      </Flex>
      <Calendar
        swiperRef={swiperRef}
        selectedDate={selectedDate}
        onChangeDate={setSelectedDate}
        onSlideChange={onSlideChange}
      />
      <ShiurimList items={mockData.slice(0, 5)} />
    </div>
  )
}

export default NewShiurim
