import { Button } from 'antd'
import { COLORS } from 'constants/colors'
import styled from 'styled-components'

import Modal from '../index'

export const ANTDModal = styled(Modal)`
  .ant-modal-header {
    margin: 0 24px;
    padding: 20px 0px;

    border-bottom: 1px solid #ebebeb;
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body {
    padding: 24px 24px;
  }

  .ant-modal-footer {
    margin: 0;
  }
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;

  color: ${COLORS.primary.dark};
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;

  color: #696b7b;

  margin-top: 12px;
`

export const FooterWrapper = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #f6f6f6;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
`

export const FooterButton = styled(Button)`
  width: 100%;
`
