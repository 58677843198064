import { ReactComponent as PlayIcon } from 'assets/icons/play-big.svg'
import dayjs from 'dayjs'

import { Image, ImageContainer, MetaInfo, Number, PlayerController, Subtitle, Title, TitleRow, Wrapper } from './styled'

type Props = { img: string; number: string; title: string; subtitle: string; date: Date; durationSeconds: number }

function ShiurimCard({ img, number, title, subtitle, date, durationSeconds }: Props) {
  return (
    <Wrapper>
      <ImageContainer>
        <Image src={img} />
        <PlayerController>
          <PlayIcon />
        </PlayerController>
      </ImageContainer>
      <TitleRow>
        <Number>{number}</Number>
        <Title>{title}</Title>
      </TitleRow>
      <Subtitle>{subtitle}</Subtitle>
      <MetaInfo>
        {date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} ·{' '}
        {dayjs.duration(durationSeconds, 'seconds').format('m:ss')} min left
      </MetaInfo>
    </Wrapper>
  )
}

export default ShiurimCard
